import React, {
  useEffect,
  Dispatch,
  useState,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import organizedFolder from "../../../assets/svg/organizedFolder.svg";
import combined from "../../../assets/svg/combined.svg";
import tips from "../../../assets/svg/tips.svg";
import iconArrow from "../../../assets/svg/iconArrow.svg";
import { permissionDeniedClick } from "../../../utilities/common-function";
import { FolderState, Folder, RolesState } from "../../../store/types";
import {
  fetchFolders,
  setActiveFolder,
  fetchProjects,
  setFolders,
  setProjects,
  getStatusSummary,
  setSelectedProject,
  setFolderLoader,
  setProjectLoader,
  fetchHyperProjects,
  setFoldersPageData,
  addFolder,
  setSelectedFolders,
  setFilter,
  setFolderBreadcrumb,
  getUsersData,
} from "../../../store/actions";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { getUrlProperties } from "../../../utilities/common-function";
import Modal from "../../shared/Modal";
import PromptPopUp from "../../shared/PromptPopUp";
import { Button, Tooltip } from "pyxis-ui-kit";
import {
  FolderOpenOutlined,
  FolderOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { FolderAndFileUploadAlert } from "../FolderAndFileUploadAlert";
import { Drawer } from "@material-ui/core";
import VLEStatus from "./VLEStatus";
interface TitleInterface {
  title: string;
  activeFolderId: number;
  changeActiveFolderInRoute: Function;
  dispatch: Dispatch<any>;
  folderType: string;
  canCreate: boolean;
  setPromptPopUpProps: Function;
}
interface ItemInterface {
  folderData: Folder;
  isActive: boolean;
  activeFolderId: number;
  level: number;
  folderFilter: Function;
  changeActiveFolderInRoute: Function;
  folderIds: number[];
  levelIds: number[];
  setLevelIds: Function;
  // leftSideBarIds:number[],
  // setLeftSideBarIds:Function,
  dispatch: Dispatch<any>;
  canManage: boolean;
  setPromptPopUpProps: Function;
}
interface ContainerInterface {
  className: string;
  style?: any;
}
interface leftSideBarInterface {
  folderType: string;
}

const Title: React.FunctionComponent<TitleInterface> = ({
  title,
  activeFolderId,
  dispatch,
  folderType,
  changeActiveFolderInRoute,
  canCreate,
  setPromptPopUpProps,
}) => {
  return (
    <>
      <div
        className={activeFolderId === 0 ? "title active" : "title"}
        onClick={() => {
          changeActiveFolderInRoute([]);
        }}
      >
        <p className='title-container'>
          <span className='main-folder'>
            <img src={combined} className='combined' />
            {title}
          </span>
        </p>
      </div>
    </>
  );
};

const Container: React.FunctionComponent<ContainerInterface> = ({
  children,
  className,
  style,
}) => {
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

const Item: React.FunctionComponent<ItemInterface> = ({
  folderData,
  levelIds,
  setLevelIds,
  folderIds,
  isActive,
  folderFilter,
  level,
  activeFolderId,
  dispatch,
  changeActiveFolderInRoute,
  canManage,
  setPromptPopUpProps,
}) => {
  const { name, id } = folderData;
  const [showChildFolders, setShowChildFolders] = useState(false);
  const filteredFolders: Folder[] = folderFilter(id);
  const margin = `${level * 2}rem`;

  useEffect(() => {
    setShowChildFolders(folderIds.indexOf(id) > -1);
  }, [folderIds]);

  return (
    <React.Fragment>
      <div className='item'>
        <div
          className={
            (showChildFolders && filteredFolders.length > 0) ||
            levelIds.includes(id)
              ? "left noselect show-path"
              : isActive
              ? "left-active left noselect"
              : "left noselect"
          }
          style={{ marginLeft: margin }}
          onClick={(e) => {
            setShowChildFolders((value) => !value);
            if (!isActive) {
              changeActiveFolderInRoute([...levelIds, id]);
            }
            e.stopPropagation();
          }}
        >
          <span className='folder-title'>
            {showChildFolders || levelIds.includes(id) ? (
              <FolderOpenOutlined className='folder-icon' />
            ) : (
              <FolderOutlined className='folder-icon' />
            )}
            <Tooltip
              placement='top'
              title={name}
              content={<span className='folder'>{name}</span>}
            ></Tooltip>
          </span>
          <span
            className={
              (showChildFolders && filteredFolders.length > 0) ||
              levelIds.includes(id)
                ? "active icon-container"
                : "icon-container"
            }
          >
            <img src={iconArrow} className='icon-arrow' />
          </span>
        </div>
        {/* {canManage && <div className="icon small" onClick={(e)=>{
                setOpenMenu(true)
                e.stopPropagation();
            }}>
                <span ref={optionRef} className={isActive? "option show":"option"}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6667 8.00008C10.6667 7.64646 10.8072 7.30732 11.0573 7.05727C11.3073 6.80722 11.6465 6.66675 12.0001 6.66675C12.3537 6.66675 12.6928 6.80722 12.9429 7.05727C13.1929 7.30732 13.3334 7.64646 13.3334 8.00008C13.3334 8.3537 13.1929 8.69284 12.9429 8.94289C12.6928 9.19294 12.3537 9.33341 12.0001 9.33341C11.6465 9.33341 11.3073 9.19294 11.0573 8.94289C10.8072 8.69284 10.6667 8.3537 10.6667 8.00008ZM6.66675 8.00008C6.66675 7.64646 6.80722 7.30732 7.05727 7.05727C7.30732 6.80722 7.64646 6.66675 8.00008 6.66675C8.3537 6.66675 8.69284 6.80722 8.94289 7.05727C9.19294 7.30732 9.33341 7.64646 9.33341 8.00008C9.33341 8.3537 9.19294 8.69284 8.94289 8.94289C8.69284 9.19294 8.3537 9.33341 8.00008 9.33341C7.64646 9.33341 7.30732 9.19294 7.05727 8.94289C6.80722 8.69284 6.66675 8.3537 6.66675 8.00008ZM2.66675 8.00008C2.66675 7.64646 2.80722 7.30732 3.05727 7.05727C3.30732 6.80722 3.64646 6.66675 4.00008 6.66675C4.3537 6.66675 4.69284 6.80722 4.94289 7.05727C5.19294 7.30732 5.33341 7.64646 5.33341 8.00008C5.33341 8.3537 5.19294 8.69284 4.94289 8.94289C4.69284 9.19294 4.3537 9.33341 4.00008 9.33341C3.64646 9.33341 3.30732 9.19294 3.05727 8.94289C2.80722 8.69284 2.66675 8.3537 2.66675 8.00008Z" fill="white"/>
                    </svg>
                </span>
            </div>} */}
        {/* {openMenu && canManage && <Menu open={openMenu} anchorRef={optionRef}  handleClose={()=>setOpenMenu(false)}>
                <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                    <MenuItem onClick={(e)=>{
                        setOpenMenu(false)
                        setPromptPopUpProps({
                            submitBtnText: "Update",
                            inputText: name||"",
                            isUpdate: true,
                            title: "Update Folder",
                            text: "Please enter a new name for the item:",
                            placeholder: "Folder name",
                            onSubmit: (value:string,setLoader:Function)=>{ 
                                setLoader(true)
                                dispatch(updateFolder({...folderData,name:value})) 
                                setLoader(false)
                                setPromptPopUpProps(null)
                            }
                        })
                    }}>Rename</MenuItem>
                    <MenuItem onClick={()=>{
                        setOpenMenu(false);
                        openAlertModal(true);
                    }}>Delete</MenuItem>
                </MenuList>
            </Menu>}
            {isAlertModal && (
                <Modal>
                    <AlertPopUp
                        title="Are you sure?"
                        text="Are you sure you want to remove this color?"
                        submittext="Yes"
                        canceltext="No"
                        onSubmit={(setLoader:Function) => {
                            setLoader(true);
                            dispatch(deleteFolders(id, (res:any, err:boolean) => {
                                setLoader(false);
                                if(!err) {
                                    openAlertModal(false);
                                }
                            }))
                        }}
                        onCancel={() => {
                            openAlertModal(false);
                        }}
                    />
                </Modal>
            )} */}
      </div>
      {filteredFolders.length > 0 && (
        <div className={showChildFolders ? "subfolder show" : "subfolder"}>
          {filteredFolders.map((subFolderData, childIndex) => (
            <Item
              key={subFolderData.id}
              activeFolderId={activeFolderId}
              folderFilter={folderFilter}
              folderData={subFolderData}
              levelIds={[...levelIds, id]}
              setLevelIds={setLevelIds}
              folderIds={folderIds}
              setPromptPopUpProps={setPromptPopUpProps}
              changeActiveFolderInRoute={changeActiveFolderInRoute}
              isActive={activeFolderId === subFolderData.id}
              dispatch={dispatch}
              level={level + 1}
              canManage={canManage}
            />
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

const LeftSideBar: React.FunctionComponent<leftSideBarInterface> = ({
  folderType,
}) => {
  const state = useSelector((state: { folders: FolderState }) => state.folders);
  const userPermission: any = useSelector((state: { roles: RolesState }) =>
    state.roles.userPermissions.length !== 0 ? state.roles.userPermissions : []
  );
  const { folders, activeFolderId, folderLoading, filter } = state;
  const folderFilter: Function = useCallback(
    (id: number) =>
      folders.filter(
        (folder) => folder.parent === id && folder.type === folderType
      ),
    [folders]
  );
  const filteredFolders: Folder[] = folderFilter(0);
  const dispatch = useDispatch();
  const selectedProductId = useSelector(
    (state: { roles: RolesState }) => state.roles.selectedProductId
  );
  const urlSearchParam = new URLSearchParams(getUrlProperties().search);
  const folderIds =
    urlSearchParam
      .get("folder")
      ?.split("-")
      .map((x) => +x) || [];
  const [promptPopUpProps, setPromptPopUpProps] = useState<any>(null);
  const [levelIds, setLevelIds] = useState<any>([]);
  const [drawerVisible, setDrawerVisible] = useState("");
  const history = useHistory();
   const VLEProductId = '63d3c6e73758980013479110'
  const changeActiveFolderInRoute = useCallback((ids: number[]) => {
    const { baseUrl, search } = getUrlProperties();
    if (ids.length > 1) {
      ids.shift();
      let newurl = baseUrl + "?folder=" + ids.join("-");
      window.history.pushState({ path: newurl }, "", newurl);
      dispatch(setActiveFolder(ids[ids.length - 1]));
    } else {
      window.history.pushState({ path: baseUrl }, "", baseUrl);
      dispatch(setActiveFolder(0));
    }
    dispatch(setFilter(null));
    dispatch(setFolderLoader(true));
    dispatch(
      fetchFolders(
        {
          type: folderType,
          parent: ids.length > 0 ? [ids[ids.length - 1]] : undefined,
        },
        (res: any, err: boolean) => {
          dispatch(setFolderLoader(false));
          if (!err) {
            dispatch(addFolder(res.results));
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    dispatch(setFolderLoader(true));
    if (folderIds.length > 0) {
      dispatch(
        fetchFolders(
          { type: folderType, parent: [0, ...folderIds] },
          (res: any, err: boolean) => {
            dispatch(setFolderLoader(false));
            const breadcrumbFolders: any[] = [];
            if (!err) {
              res.results.map((folder: Folder) => {
                if (folderIds.includes(folder.id)) {
                  breadcrumbFolders.push(folder);
                }
              });
              dispatch(setFolderBreadcrumb(breadcrumbFolders));
              dispatch(addFolder(res.results));
            }
          }
        )
      );
      dispatch(setActiveFolder(folderIds[folderIds.length - 1]));
    } else {
      dispatch(
        fetchFolders({ type: folderType }, (res: any, err: boolean) => {
          dispatch(setFolderLoader(false));
          if (!err) {
            dispatch(addFolder(res.results));
          }
        })
      );
    }
    return () => {
      dispatch(setFolders([]));
      dispatch(setActiveFolder(-1));
      dispatch(setFilter(null));
    };
  }, []);

  useEffect(() => {
    dispatch(setFolderBreadcrumb([]));
    const breadcrumbFolders: any[] = [];
    folders.map((folder: Folder) => {
      if (folderIds.includes(folder.id)) {
        breadcrumbFolders.push(folder);
      }
    });
    dispatch(setFolderBreadcrumb(breadcrumbFolders));
    if (activeFolderId > -1) {
      if (folderType === "project") {
        dispatch(setProjectLoader(true));
        dispatch(
          fetchProjects(
            {
              ...(filter || {}),
              page: 1,
              folder_id: activeFolderId ? activeFolderId : undefined,
              page_size: 20,
              order_by: "-created_at",
            },
            (res: any, err: boolean) => {
              if (!err) {
                dispatch(
                  setFoldersPageData({
                    count: res.count,
                    current_page: res.current_page,
                    page_size: res.page_size,
                    pages: res.pages,
                  })
                );
                res = res.data;
                let ids = res.reduce(
                  (acc: number[], val: any) => [...acc, val.id],
                  []
                );
                let userIds = res.reduce((acc: any, value: any) => {
                  if (!acc.includes(value.created_by)) {
                    acc.push(value.created_by);
                  }
                  return acc;
                }, []);
                if (userIds.length > 0) {
                  dispatch(
                    getUsersData(
                      { userIds: userIds },
                      (userres: any, usererr: boolean) => {
                        if (!usererr) {
                          let { users } = userres.data;
                          res.map((project: any, index: number) => {
                            project.created_by =
                              users.find(
                                (user: any) => user._id === project.created_by
                              ) || "";
                          });
                        }
                      }
                    )
                  );
                }
                dispatch(
                  getStatusSummary(
                    { ids, entity_type: folderType },
                    (summaryRes: any, summaryErr: boolean) => {
                      dispatch(setProjectLoader(false));
                      if (!summaryErr) {
                        let {
                          data: { results },
                        } = summaryRes;
                        results.forEach((d: any) => {
                          let found: any = res.findIndex(
                            (obj: any) => obj.id.toString() === d.entity_id
                          );
                          if (found !== -1) {
                            res[found]["status"] = d.status;
                            res[found]["entity_id"] = d.entity_id;
                          }
                        });
                      }
                      dispatch(setProjects(res));
                    }
                  )
                );
              } else {
                dispatch(setProjectLoader(false));
              }
            }
          )
        );
      } else if (folderType === "hpproject") {
        dispatch(setProjectLoader(true));
        dispatch(
          fetchHyperProjects(
            {
              ...(filter || {}),
              page: 1,
              folder: activeFolderId ? activeFolderId : undefined,
              page_size: 20,
              order_by: "-created_at",
            },
            (res: any, err: boolean) => {
              if (!err) {
                let ids = res.reduce(
                  (acc: number[], val: any) => [...acc, val.id],
                  []
                );
                let userIds = res.reduce(
                  (acc: number[], val: any) => [...acc, val.created_by],
                  []
                );
                if (userIds.length > 0) {
                  dispatch(
                    getUsersData(
                      { userIds: userIds },
                      (userres: any, usererr: boolean) => {
                        if (!usererr) {
                          let { users } = userres.data;
                          res.map((project: any, index: number) => {
                            project.created_by =
                              users.find(
                                (user: any) => user._id === project.created_by
                              ) || "";
                          });
                        }
                      }
                    )
                  );
                }
                dispatch(
                  getStatusSummary(
                    { ids, entity_type: folderType },
                    (summaryRes: any, summaryErr: boolean) => {
                      dispatch(setProjectLoader(false));
                      if (!summaryErr) {
                        let {
                          data: { results },
                        } = summaryRes;
                        results.forEach((d: any) => {
                          let found: any = res.findIndex(
                            (obj: any) => obj.id.toString() === d.entity_id
                          );
                          if (found !== -1) {
                            res[found]["status"] = d.status;
                            res[found]["entity_id"] = d.entity_id;
                          }
                        });
                      }
                      dispatch(setProjects(res));
                    }
                  )
                );
              } else {
                dispatch(setProjectLoader(false));
              }
            }
          )
        );
      }
    }
    return () => {
      dispatch(setSelectedProject([]));
      dispatch(setProjects([]));
      dispatch(setFoldersPageData(null));
      dispatch(setSelectedFolders([]));
    };
  }, [activeFolderId, filter]);

  const handleClick = () => {
    if (userPermission.includes("create-new-banners")) {
      setDrawerVisible("folder");
    } else {
      permissionDeniedClick();
    }
  };

  return (
    <React.Fragment>
      <Container className='dashboard-leftsidebar'>
        {!folderLoading && filteredFolders.length === 0 ? (
          <div className='empty-folder'>
            <img src={organizedFolder} className='icon' />
            <div className='organized'>Keep Everything Organized</div>
            <Button className='create' type='default' onClick={handleClick}>
              <PlusCircleOutlined />
              Create a new folder
            </Button>
            <img src={tips} className='tip-icon' />
            <div className='tip'>
              You can create folders and keep your projects organized
            </div>
          </div>
        ) : (
          <>
            <Title
              title='Main Folder'
              dispatch={dispatch}
              activeFolderId={activeFolderId}
              changeActiveFolderInRoute={changeActiveFolderInRoute}
              folderType={folderType}
              setPromptPopUpProps={setPromptPopUpProps}
              canCreate={userPermission.includes("create-new-banners")}
            />
            <div
              className=''
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Container
                className='list'
                style={
                  selectedProductId == VLEProductId
                    ? { height: "calc(100% - )" }
                    : { height: "calc(100% - 100px)" }
                }
              >
                {filteredFolders.map((folderData, index) => (
                  <Item
                    key={folderData.id}
                    folderData={folderData}
                    activeFolderId={activeFolderId}
                    folderFilter={folderFilter}
                    folderIds={folderIds}
                    setPromptPopUpProps={setPromptPopUpProps}
                    changeActiveFolderInRoute={changeActiveFolderInRoute}
                    isActive={activeFolderId === folderData.id}
                    dispatch={dispatch}
                    level={0}
                    setLevelIds={setLevelIds}
                    levelIds={[0]}
                    // leftSideBarIds={leftSideBarIds}
                    // setLeftSideBarIds={setLeftSideBarIds}
                    canManage={userPermission.includes("create-new-banners")}
                  />
                ))}
              </Container>
              {selectedProductId == VLEProductId && (
                <Button
                  className='download-vle-btn'
                  onClick={() => {
                    history.push("/cocreate/vle-status");
                  }}
                >
                  Download VLE status
                </Button>
              )}
            </div>
          </>
        )}
      </Container>
      {drawerVisible === "folder" && (
        <FolderAndFileUploadAlert
          isDrawerVisible={
            drawerVisible === "folder" || drawerVisible === "uploadFile"
          }
          type={folderType}
          changeActiveFolderInRouteForward={changeActiveFolderInRoute}
          projectType='folder'
          drawerVisibleFor={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          isGlobalCreate={false}
        />
      )}
    </React.Fragment>
  );
};

export default LeftSideBar;
